export type SessionState = {
	email: string;
	language: "nb-NO" | "en-GB";
	name?: string;
	error: string;
	firstName?: string;
	lastName?: string;
	company?: string;
	hasIdentity: boolean;
	hasPersonalia: boolean;
	isAuthenticated: boolean;
	token?: string;
	tokenSent: boolean;
};

export let language: "nb-NO" | "en-GB" = "nb-NO";

async function doApiRequest(action: string, body?: RequestInit): Promise<SessionState> {
	let response = await fetch(`/api/personnel/course/external/${action}`, { method: "POST", ...body });
	let state = await response.json();

	language = state.Language ?? language;

	return {
		email: state.Email ?? "",
		name: state.Name,
		firstName: state.FirstName,
		lastName: state.LastName,
		company: state.Company,
		error: state.Error,
		hasIdentity: state.HasIdentity,
		hasPersonalia: state.HasPersonalia,
		isAuthenticated: state.IsAuthenticated,
		tokenSent: state.TokenSent,
		language: state.Language ?? language,
	};
}

export type Course = {
	CompletedDate?: Date | string;
	CourseID: number;
	CurrentSlide?: number;
	Description: string;
	Duration: number;
	Title: string;
};

export async function getCourses(): Promise<Course[]> {
	let response = await fetch("/api/personnel/course/external/courses");
	return await response.json();
}

export async function setLanguage(newLanguage: "nb-NO" | "en-GB") {
	let formData = new FormData();
	formData.append("Language", newLanguage);
	return await doApiRequest("set-language", { body: formData });
}

export async function getSessionState(): Promise<SessionState> {
	return await doApiRequest("get-state");
}

export async function logout() {
	return await doApiRequest("logout");
}

export async function requestToken(email: string) {
	let formData = new FormData();
	formData.append("email", email);

	return await doApiRequest("request-token", { body: formData });
}

export async function submitPersonalia(firstName: string, lastName: string, company: string) {
	let formData = new FormData();
	formData.append("FirstName", firstName);
	formData.append("LastName", lastName);
	formData.append("Company", company);

	return await doApiRequest("submit-personalia", { body: formData });
}

export async function verifyToken(token: string) {
	let formData = new FormData();
	formData.append("token", token);

	return await doApiRequest("verify-token", { body: formData });
}
